export const setStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const getStorage = (key) => {
    try {
        const data = localStorage.getItem(key);
        if (data[0] === "{" || data[0] === "[") {
            return JSON.parse(data);
        } else {
            return data;
        }
    } catch (e) {
        return null;
    }
};

export const removeStorage = (key) => {
    localStorage.removeItem(key);
};
