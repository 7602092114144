import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useEmblaCarousel from "embla-carousel-react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { PrevButton, NextButton } from "common/components/CarouselArrowButtons";

import { onMountBrowser } from "features/modules/browse/reducers/browseSlice";

import "./Browse.css";
import Carousel from "common/components/Carousel";

function Browse() {
    const dispatch = useDispatch();

    const { choicePool, freeScroll, hideCardToggle } = useSelector((state) => state.quiz);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const [emblaRef, emblaApi] = useEmblaCarousel({ inViewThreshold: 0, loop: true, dragFree: freeScroll });
    const [showMask, setShowMask] = useState(false);

    const [arrowKeys, setArrowKeys] = useState({ direction: null, toggle: false });

    useEffect(() => {
        dispatch(onMountBrowser());
    }, [dispatch]);

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on("select", () => {
                setShowMask(hideCardToggle);
            });
        }
    }, [emblaApi, hideCardToggle]);

    useEffect(() => {
        if (emblaApi) {
            emblaApi.reInit();
        }
    }, [choicePool]);

    function handleKeyDown(event) {
        if (
            event.key === "ArrowLeft" ||
            event.key === "ArrowRight" ||
            event.key === "ArrowUp" ||
            event.key === "ArrowDown"
        ) {
            event.preventDefault();
        }
        setArrowKeys({ direction: event.key, toggle: !arrowKeys.toggle });
    }

    const getCard = (direction) => {
        hideCardToggle && setShowMask(true);
        if (direction === "next") {
            emblaApi.scrollNext();
        } else if (direction === "previous") {
            emblaApi.scrollPrev();
        }
    };

    useEffect(() => {
        if (arrowKeys.direction === "ArrowLeft") {
            getCard("previous");
        } else if (arrowKeys.direction === "ArrowRight") {
            getCard("next");
        } else if (arrowKeys.direction === "ArrowUp") {
            hideCardToggle && setShowMask(!showMask);
        }
    }, [arrowKeys, dispatch]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        setShowMask(hideCardToggle);
    }, [hideCardToggle]);

    const OPTIONS = { inViewThreshold: 0, loop: true, dragFree: freeScroll };
    const SLIDE_COUNT = choicePool.length;
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
    
    return (
        <div className="embla">
            <Carousel
                choicePool={choicePool}
                slides={SLIDES}
                options={OPTIONS}
                setShowMask={setShowMask}
                hideCardToggle={hideCardToggle}
                showMask={showMask}
                emblaRef={emblaRef} 
                emblaApi={emblaApi}
            />
            {isMobile && (
                <>
                    <PrevButton onClick={() => getCard("previous")} enabled />
                    <NextButton onClick={() => getCard("next")} enabled />
                </>
            )}
        </div>
    );
}

export default Browse;
