import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;

const CarouselSlide = ({ card, showMask, inView }) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const { convertToBW, invertImage } = useSelector((state) => state.ui);

    const setLoaded = useCallback(() => {
        if (inView) setHasLoaded(true);
    }, [inView, setHasLoaded]);

    const imgSrc = `/images/${card?.source}/cards/${card?.urlFriendlyName}.png`;
    return (
        <div className="embla__slide">
            <Loader hidden={hasLoaded} />
            <div className="embla__lazy-load">
                {true && <span className="embla__lazy-load__spinner" />}
                <img
                    className="embla__slide__img embla__lazy-load__img"
                    src={inView ? imgSrc : PLACEHOLDER_SRC}
                    data-src={imgSrc}
                    alt={card.urlFriendlyName}
                    onLoad={setLoaded}
                    style={{
                        clipPath: showMask
                            ? "polygon(12% 12%, 92% 12%, 92% 55%, 12% 55%)"
                            : "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        display: hasLoaded ? "block" : "none",
                        filter: convertToBW ? "grayscale(100%)" : "grayscale(0%)",
                        transform: invertImage ? "scale(-1)" : "scale(1)",
                        transition: "0.222s",
                    }}
                    draggable="false"
                    onError={(e) =>
                        (e.target.onerror = null)(
                            (e.target.src = `https://via.placeholder.com/310x223?text=Image+Not+Found: ${card?.urlFriendlyName}`)
                        )
                    }
                />
            </div>
        </div>
    );
};

export default CarouselSlide;
