import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";

import { onReset } from "./../reducers/quizSlice";
import ModalContainer from "common/components/ModalContainer";

const GameOverReport = ({ askedChoicesMultiplayer }) => {
    const dispatch = useDispatch();
    const { askedChoices, choiceType } = useSelector((state) => state.quiz);

    let displayedAskedChoices = askedChoices;

    if (askedChoicesMultiplayer) {
        displayedAskedChoices = askedChoicesMultiplayer;
    }

    const [openModal, setOpenModal] = React.useState(false);
    const [selectedCard, setSelectedCard] = React.useState(null);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleReplay = () => {
        dispatch(onReset());
    };

    const handleCardSelection = (card) => {
        setOpenModal(true);
        setSelectedCard(card);
    };

    return (
        <div className="game-over-report">
            <ModalContainer openModal={openModal} setOpenModal={setOpenModal} closeOnClickAnywhere fullScreen>
                <img
                    src={`/images/${selectedCard?.source}/cards/${selectedCard?.urlFriendlyName}.png`}
                    alt={selectedCard?.name}
                    draggable={false}
                    style={{ minWidth: "40%", maxWidth: "65%", margin: "auto" }}
                />
            </ModalContainer>
            <h1 className="game-over-report__title">Game Over</h1>
            {!askedChoicesMultiplayer && (
                <Button sx={{ margin: "10px" }} variant="contained" onClick={handleReplay}>
                    Play again
                </Button>
            )}

            <div>
                {displayedAskedChoices.map((selection, index) => {
                    const correctChoice = selection.choices.find((choice) => choice.isCorrectChoice);
                    const selectedChoice = selection.choices[selection.selectionIndex];

                    let tabLabel = correctChoice[choiceType];
                    if (tabLabel.length > 20) {
                        tabLabel = tabLabel.substring(0, 40) + "...";
                    }

                    return (
                        <Accordion
                            key={index}
                            sx={{
                                backgroundColor: selectedChoice.isCorrectChoice ? "green" : "red",
                            }}
                            expanded={expanded === "panel" + index}
                            onChange={handleChange("panel" + index)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Avatar
                                    src={`/images/${correctChoice?.source}/artwork/${correctChoice?.urlFriendlyName}.png`}
                                    sx={{ width: 56, height: 56 }}
                                    variant="square"
                                ></Avatar>

                                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                                    {tabLabel}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{ textAlign: "center", display: "grid", gridTemplateColumns: "repeat(3, 1fr);" }}
                            >
                                {selection.choices.map((choice, index) => {
                                    let backgroundColor;
                                    if (choice.isCorrectChoice) {
                                        backgroundColor = "green";
                                    } else if (selectedChoice.id === choice.id) {
                                        backgroundColor = "yellow";
                                    }

                                    return (
                                        <div
                                            key={index}
                                            className="game-over-report__selections-wrapper"
                                            style={{
                                                padding: "10px",
                                                margin: "10px",
                                                backgroundColor,
                                                display: "inline-block",
                                                borderRadius: "10px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleCardSelection(choice)}
                                        >
                                            <img
                                                alt={choice?.name}
                                                src={`/images/${choice?.source}/cards/${choice?.urlFriendlyName}.png`}
                                                style={{ width: "100%" }}
                                            />
                                            {choice?.isCorrectChoice && <div>Correct</div>}
                                            {!selectedChoice.isCorrectChoice && selectedChoice.id === choice.id && (
                                                <div>Your Choice</div>
                                            )}
                                        </div>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div>
    );
};

export default GameOverReport;
