import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import './AdditionalFilters.css';

function AdditionalFilters({ additionalFilters, location }) {
    return (
        <div className="additional_filters">
            <div className="additional_filters__title">
                <p>Additional Modes & Filters</p>
            </div>
            <div className="additional_filters__contents">
                {additionalFilters.map((filter, index) => {
                    const isModuleCompatible = filter.moduleCompatibilities.some((module) =>
                        location.pathname.toLowerCase().includes(module)
                    );
                    if (!isModuleCompatible) return null;

                    return (
                        <FormControlLabel
                            key={index}
                            value={filter.value}
                            control={
                                <Checkbox
                                    className="black-white_checkbox"
                                    onChange={filter.onChange}
                                    value={filter.value}
                                    checked={filter.value}
                                />
                            }
                            label={filter.label}
                            labelPlacement="top"
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default AdditionalFilters;
