import "./GameDetails.css";
import { useSelector } from "react-redux";
import useTimer from "common/utils/useTimer";
import { useEffect } from "react";

function GameDetails() {
    const { getFormattedTime, startTimer, stopTimer } = useTimer();
    const { questionsLeft, askedChoices, numberOfQuestions } = useSelector((state) => state.quiz);
    const correctlyAnswered = askedChoices.filter((selection) => selection.choices[selection.selectionIndex]?.isCorrectChoice).length;
    useEffect(() => {
        // if (askedChoices.length > 0) {
        //     startTimer();
        // }
        // return () => {
        //     stopTimer();
        // };
    }, [askedChoices]);

    return (
        <div className="game-details-container">
            <div id="gameDetails_items">
                <div className="quiz_result_details">
                    <div>Asked</div>
                    <div>
                        {askedChoices.length} of {numberOfQuestions}
                    </div>
                </div>
                <div className="quiz_result_details">
                    <div>Left</div>
                    <div>{questionsLeft}</div>
                </div>
                <div className="quiz_result_details">
                    <div>Correct</div>
                    <div>{correctlyAnswered}</div>
                </div>
                <div className="quiz_result_details">
                    <div>Percent</div>
                    <div>
                        {askedChoices.length > 0 ? Math.round((100 * correctlyAnswered) / askedChoices.length) : 100}%
                    </div>
                </div>
                {/* <div className="quiz_result_details">
                    <div>Time</div>
                    <div>{getFormattedTime()}</div>
                </div> */}
            </div>
        </div>
    );
}

export default GameDetails;
