import Gun from "gun";
import "gun/lib/unset.js";
import "gun/lib/open.js";

import {
    onMountMultiplayer,
    onUnmountMultiplayer,
    onGameCreationMultiplayer,
    onPlayerSync,
    onGameSync,
    onChoiceSelectionMultiplayer,
    getNextQuestionMultiplayer,
    onResetMultiplayer,
} from "features/modules/multiplayer/reducers/multiplayerSlice";

import { getNextQuestion, onMountArtwork } from "features/modules/quiz/reducers/quizSlice";

const gun = Gun({
    peers: ["https://gun-server.herokuapp.com/gun"],
});

const gunMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        if (action.type === onMountMultiplayer.type) {
            const { gameId, userHash } = action.payload;
            if (!gameId || !userHash) {
                return next(action);
            }

            const { quiz } = getState();
            action.payload.quiz = quiz;

            const user = gun.get("users").get(userHash);
            user.once((userDetails) => {
                if (!userDetails) {
                    // On new player join, create an user entry
                    user.put({ id: userHash, username: "Guest-" + userHash });
                }
            });

            const game = gun.get("games").get(gameId);
            const players = game.get("players");
            const gameState = game.get("state");

            players.once((allPlayers) => {
                // If user is not in the game, add them
                if (!allPlayers || !allPlayers[userHash]) {
                    players.set(user);
                }
            });
            try {
                players.open((currentPlayerState) => {
                    dispatch(onPlayerSync({ currentPlayerState }));
                });

                gameState.open((data) => {
                    const currentGameState = JSON.parse(data);
                    dispatch(onGameSync({ currentGameState }));
                });
            } catch (e) {
                console.error("error", e);
            }
        } else if (action.type === onChoiceSelectionMultiplayer.type) {
            const { multiplayer } = getState();
            const { activeGameId, questionNumberIndex } = multiplayer;
            const { chosenCard, userHashStorage } = action.payload;

            const gameState = gun.get("games").get(activeGameId).get("state");

            gameState.once((data) => {
                const dataObj = JSON.parse(data);
                const { askedChoices, choices } = dataObj.quiz;

                if (
                    !askedChoices.find(
                        (askedChoice) =>
                            askedChoice.questionNumberIndex === questionNumberIndex &&
                            askedChoice.userHash === userHashStorage
                    )
                ) {
                    askedChoices.push({
                        questionNumberIndex,
                        userHash: userHashStorage,
                        choices,
                        selectionIndex: choices.findIndex((choice) => choice.id === chosenCard.id),
                    });
                } else {
                    console.error("Already answered");
                }

                gameState.put(
                    JSON.stringify({
                        ...dataObj,
                        askedChoices,
                    })
                );
            });
        } else if (action.type === getNextQuestionMultiplayer.type) {
            const { multiplayer, quiz } = getState();
            const { activeGameId } = multiplayer;

            const game = gun.get("games").get(activeGameId);
            const gamesState = game.get("state");
            const playersState = game.get("players");

            const { askedChoices } = quiz;
            const { questionNumberIndex } = multiplayer;

            playersState.once((data) => {
                const currentPlayersState = Object.keys(data).filter((key) => key !== "_");
                const playersSubmitted = askedChoices?.filter(
                    (askedChoice) => askedChoice.questionNumberIndex === questionNumberIndex
                );
                if (playersSubmitted && currentPlayersState.length > 0) {
                    const playersSubmittedKeys = Object.keys(playersSubmitted);
                    if (playersSubmittedKeys.length >= currentPlayersState.length) {
                        dispatch(getNextQuestion());

                        const { quiz, multiplayer, ui, browse } = getState();

                        gamesState.once((data) => {
                            const dataObj = JSON.parse(data);
                            gamesState.put(
                                JSON.stringify({
                                    ...dataObj,
                                    quiz: {
                                        ...quiz,
                                        questionsLeft: quiz.questionsLeft,
                                    },
                                    ui: {
                                        ...ui,
                                        showGameOverReport: quiz.questionsLeft < 1,
                                    },
                                    multiplayer: {
                                        ...multiplayer,
                                        questionNumberIndex: multiplayer.questionNumberIndex + 1,
                                    },
                                    browse,
                                })
                            );
                        });
                    }
                }
            });
        } else if (action.type === onGameCreationMultiplayer.type) {
            dispatch(onMountArtwork());
            const { userHash } = action.payload;
            const { quiz, app, ui, browse } = getState();

            const { activeSubCategories } = app;

            const { newGameId } = action.payload;

            const currentGameState = {
                quiz: {
                    ...quiz,
                    askedChoices: [],
                },
                multiplayer: {
                    host: userHash,
                    activeGameId: newGameId,
                },
                app: {
                    activeSubCategories,
                },
                ui,
                browse,
            };

            action.payload.ownerDetails = {
                id: userHash,
                username: "Guest-" + userHash,
            };

            action.payload.currentGameState = currentGameState;

            const game = gun.get("games").get(newGameId);

            const gameState = game.get("state");
            gameState.put(JSON.stringify(currentGameState));
            gameState.open((data) => {
                const currentGameState = JSON.parse(data);
                dispatch(onGameSync({ currentGameState }));
            });

            const user = gun.get("users").get(userHash);
            const players = game.get("players");
            players.set(user);
            players.open((currentPlayerState) => {
                dispatch(onPlayerSync({ currentPlayerState }));
            });
        } else if (action.type === onResetMultiplayer.type) {
            const {
                multiplayer: { activeGameId },
            } = getState();

            const gameState = gun.get("games").get(activeGameId).get("state");

            next(action);

            const { multiplayer, quiz, ui } = getState();

            const currentGameState = {
                quiz,
                multiplayer,
                ui,
            };

            gameState.put(JSON.stringify(currentGameState));

            // return;
        } else if (action.type === onUnmountMultiplayer.type) {
            const { multiplayer } = getState();
            const { activeGameId, userHash } = multiplayer;

            if (!activeGameId || !userHash) return;

            const user = gun.get("users").get(userHash);
            const players = gun.get("games").get(activeGameId).get("players");
            players.unset(user);

            // unsubscribe from game state
            gun.get("games").get(activeGameId).get("state").off();

            // unsubscribe from players
            gun.get("games").get(activeGameId).get("players").off();
        }

        next(action);
    };

export default gunMiddleware;
