const questionTypes = {
    name: {
        label: "Title",
        choicesFullWidth: true,
        prompt: "What is the title of this card?",
    },
    manaCost: {
        label: "Mana Cost",
        enforceUniqueChoices: true,
        prompt: "What is the mana cost of this card?",
        handleDisplayValue: (card) => {
            return card.manaCost.map((mana, index) => {
                let manaObj = {};
                manaObj.image = new Image();
                manaObj.image.src = `/images/icon/${mana}.png`;
                return (
                    <img
                        rel="preload"
                        key={index}
                        width="25px"
                        style={{ margin: 2, pointerEvents: "none" }}
                        src={manaObj.image.src}
                        alt={mana}
                    />
                );
            });
        },
    },
    type: {
        label: "Type",
        enforceUniqueChoices: true,
        choicesFullWidth: true,
        prompt: "What is the type of this card?",
    },
    rarity: {
        label: "Rarity",
        prompt: "What is the rarity of this card?",
        selectionList: ["common", "uncommon", "rare", "mythic"],
    },
    text: {
        label: "Text",
        denseText: true,
        choicesFullWidth: true,
        prompt: "What is the text written on this card?",
    },
    powerToughness: {
        label: "Power/Toughness",
        enforceUniqueChoices: true,
        prompt: "What is the power/toughness of this card?",
    },
    keywords: {
        label: "Keywords",
        choicesFullWidth: true,
        prompt: "What kind of keywords does this card have?",
        handleDisplayValue: (card) => {
            return card.keywords?.map((value) => value).join(", ");
        },
    },
};
export { questionTypes };
