import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    // Multiplayer
    host: "",
    activeGameId: null,
    players: [],
    questionNumberIndex: 0,
    showResults: true,
};

const syncClientMultiplayerState = (state, action) => {
    const { multiplayer } = action.payload.currentGameState;

    Object.keys(state).forEach((key) => {
        if (multiplayer[key]) {
            state[key] = multiplayer[key];
        }
    });
};

export const multiplayerSlice = createSlice({
    name: "multiplayer",
    initialState,
    reducers: {
        onPlayerSync: (state, action) => {
            const { currentPlayerState } = action.payload;
            let players = Object.keys(currentPlayerState);
            let formattedPlayers = players.map((player) => {
                return {
                    id: currentPlayerState[player].id,
                    username: currentPlayerState[player].username,
                };
            });

            state.players = formattedPlayers;
        },
        onGameSync: (state, action) => {
            syncClientMultiplayerState(state, action);
        },
        onLobbyArrivalMultiplayer: (state, action) => {
            Object.keys(state).forEach((key) => {
                state[key] = initialState[key];
            });
        },
        onGameCreationMultiplayer: (state, action) => {
            const { ownerDetails } = action.payload;
            state.host = ownerDetails.id;
            state.players = [ownerDetails];
            state.activeGameId = action.payload.newGameId;
            syncClientMultiplayerState(state, action);
        },
        onResetMultiplayer: (state, action) => {
            const doNotErase = ["players", "host", "activeGameId"];

            Object.keys(state).forEach((key) => {
                if (!doNotErase.includes(key)) {
                    state[key] = initialState[key];
                }
            });
        },
        onChoiceSelectionMultiplayer: (state, action) => {},
        onMountMultiplayer: (state, action) => {},
        onUnmountMultiplayer: (state, action) => {},
        getNextQuestionMultiplayer: (state, action) => {},
    },
});

export const {
    onMountMultiplayer,
    onUnmountMultiplayer,
    onGameCreationMultiplayer,
    onChoiceSelectionMultiplayer,
    getNextQuestionMultiplayer,
    onPlayerNameChange,
    onLobbyArrivalMultiplayer,
    onGameSync,
    onPlayerSync,
    onResetMultiplayer,
} = multiplayerSlice.actions;

export default multiplayerSlice.reducer;
