import "./Multiplayer.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";

import { getStorage } from "common/utils/localStorage";
import ArtworkQuiz from "features/modules/quiz/views/Artwork";

import QRCode from "react-qr-code";

import { isProdMode } from "common/utils/environment";

import {
    onMountMultiplayer,
    onGameCreationMultiplayer,
    onUnmountMultiplayer,
    onLobbyArrivalMultiplayer,
} from "./../reducers/multiplayerSlice";
import MultiplayerGameOverReport from "./../components/MultiplayerGameOverReport";
import ModalContainer from "common/components/ModalContainer";

const generateHash = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

function Multiplayer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { gameId } = useParams();
    const [userHash, setUserHash] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const { askedChoices, questionsLeft } = useSelector((state) => state.quiz);
    const { host, players, questionNumberIndex } = useSelector((state) => state.multiplayer);
    const { showGameOverReport } = useSelector((state) => state.ui);

    useEffect(() => {
        let userHashStorage = getStorage("userHash");
        let userHash = userHashStorage;
        if (!userHashStorage) {
            userHash = generateHash();
            localStorage.setItem("userHash", userHash);
        }
        setUserHash(userHash);
        dispatch(onMountMultiplayer({ gameId, userHash }));

        window.onbeforeunload = function (event) {
            dispatch(onUnmountMultiplayer());
            // get localStorage item with specified key
            const storage = JSON.parse(localStorage.getItem("gun/"));
            // clear cache if exists
            if (storage) localStorage.removeItem("gun/");
        };
        return () => {
            dispatch(onUnmountMultiplayer());
        };
    }, []);

    useEffect(() => {
        if (!gameId) {
            dispatch(onLobbyArrivalMultiplayer());
        }
    }, [gameId]);

    const handleGameCreation = () => {
        const newGameId = generateHash();
        navigate(`/multiplayer/${newGameId}`);
        dispatch(onGameCreationMultiplayer({ newGameId, userHash }));
    };

    const QRUrl = isProdMode()
        ? `https://magic.gregorytesto.com/multiplayer/${gameId}`
        : `http://192.168.1.146:3000/multiplayer/${gameId}`;

    return (
        <div className="multiplayer">
            <ModalContainer openModal={openModal} setOpenModal={setOpenModal} closeOnClickAnywhere>
                <div style={{ background: "white", padding: "16px" }}>
                    <h3 style={{padding:40}}>Share this link with your friends to join the game</h3>
                    <QRCode
                        size={128}
                        style={{ height: "auto", maxWidth: "100%", width: "150px" }}
                        value={QRUrl}
                        viewBox={`0 0 128 128`}
                    />
                </div>
            </ModalContainer>
            <div>{host === userHash ? "Host" : "Not Host"}</div>
            <div>Players: {players?.length}</div>
            <div>Questions left: {questionsLeft}</div>
            <ul>
                {players?.map((player, index) => {
                    const playerSubmission = askedChoices.find(
                        (askedChoice) =>
                            askedChoice.userHash === player.id &&
                            askedChoice.questionNumberIndex === questionNumberIndex
                    );
                    if (!playerSubmission) {
                        return null;
                    }
                    const { choices, selectionIndex } = playerSubmission;
                    let backgroundColor;
                    if (choices[selectionIndex]) {
                        if (choices[selectionIndex]?.chosenCard?.isCorrectChoice) {
                            backgroundColor = "green";
                        } else {
                            backgroundColor = "red";
                        }
                    } else {
                        backgroundColor = "yellow";
                    }
                    return (
                        <li
                            key={index}
                            style={{
                                backgroundColor,
                                listStyle: "none",
                                border: "1px solid black",
                                padding: "8px",
                                margin: "8px",
                                width: "fit-content",
                                borderRadius: "8px",
                            }}
                        >
                            {(choices[selectionIndex] ? "Submitted" : "Pending") +
                                player?.username +
                                (player.id === userHash ? "(You)" : "")}
                        </li>
                    );
                })}
            </ul>
            {gameId ? (
                <>
                    {showGameOverReport ? (
                        <MultiplayerGameOverReport />
                    ) : (
                        <>
                            <button onClick={() => setOpenModal(true)}>Shareable QR Code</button>

                            <ArtworkQuiz isMultiplayerGame />
                        </>
                    )}
                </>
            ) : (
                <Button size="large" variant="contained" onClick={handleGameCreation}>Host Session</Button>
            )}
        </div>
    );
}

export default Multiplayer;
