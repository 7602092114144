import { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { modules } from "features/app/assets/modulesConfig";

function Layout() {
    const { pathname } = useLocation();
    const [value, setValue] = useState(0);
    const handleChange = (e, newValue) => setValue(newValue);

    const { activeModules } = useSelector((state) => state.ui);

    const modulesArray = Object.keys(modules)
        .filter((name) => activeModules.includes(name) && modules[name].order)
        .sort((a, b) => modules[a].order - modules[b].order);

    useEffect(() => {
        const tabIndex = modulesArray.findIndex((module) => modules[module].path === pathname);
        setValue(tabIndex);
    }, [pathname, modulesArray]);

    return (
        <>
            <Outlet />
            <BottomNavigation
                value={value}
                onChange={handleChange}
                showLabels
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: 1.0,
                    boxShadow: "0px 0px 40px -20px",
                }}
            >
                {modulesArray.map((module, index) => {
                    const { name, path, icon } = modules[module];
                    return (
                        <BottomNavigationAction
                            key={index}
                            label={name}
                            value={index}
                            LinkComponent={Link}
                            to={path}
                            icon={icon}
                        />
                    );
                })}
            </BottomNavigation>
        </>
    );
}

export default Layout;
