import { useState } from "react";

import ModalContainer from "./../../../../common/components/ModalContainer";
import Filters from "./../Filters";

import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import "./Settings.css";

function Settings() {
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="settings">
            <IconButton sx={{ position: "fixed", right: 5, top: 5, zIndex: 1 }} onClick={() => setOpenModal(true)}>
                <SettingsIcon
                    sx={{
                        fill: "black",
                        fontSize: "40px",
                        padding: "10px",
                        boxShadow: "3px 3px 10px -2px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                    }}
                />
            </IconButton>
            <ModalContainer openModal={openModal} setOpenModal={setOpenModal}>
                <div className="modal__filter-content">
                    <Filters closeModal={closeModal} />
                </div>
            </ModalContainer>
        </div>
    );
}

export default Settings;
