import React, { useCallback, useEffect, useState } from "react";
import CarouselSlide from "./CarouselSlide";

const Carousel = ({ choicePool, emblaRef, emblaApi, setShowMask, hideCardToggle, showMask }) => {
    const [slidesInView, setSlidesInView] = useState([]);

    const findSlidesInView = useCallback(() => {
        if (!emblaApi) return;

        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off("select", findSlidesInView);
            }
            const inView = emblaApi.slidesInView(true).filter((index) => slidesInView.indexOf(index) === -1);
            return [...slidesInView, ...inView];
        });
    }, [emblaApi, setSlidesInView]);

    const onSlideClick = useCallback(
        (_hideCardToggle, _showMask) => {
            if (emblaApi && emblaApi.clickAllowed()) {
                _hideCardToggle && setShowMask(!_showMask);
            }
        },
        [emblaApi]
    );

    useEffect(() => {
        if (!emblaApi) return;
        findSlidesInView();
        emblaApi.on("select", findSlidesInView);
        emblaApi.on("reInit", findSlidesInView);
    }, [emblaApi, findSlidesInView]);

    const calcCardsToDisplay = (index) => {
        const cardsToShow = [index - 2, index - 1, index, index + 1];
        return cardsToShow.some((cardPos) => slidesInView.indexOf(cardPos) > -1);
    };

    return (
        <div className="embla embla__viewport" ref={emblaRef}>
            <div className="embla__container">
                {choicePool.map((card, index) => (
                    <div className="embla__slide" key={index} onClick={() => onSlideClick(hideCardToggle, showMask)}>
                        <div id="card-img-container">
                            <CarouselSlide
                                key={index}
                                index={index}
                                card={card}
                                showMask={showMask}
                                inView={calcCardsToDisplay(index)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
