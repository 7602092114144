const delayMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (action?.payload?.meta?.delay) {
            setTimeout(() => {
                next(action);
            }, action.payload.meta.delay);
        } else {
            next(action);
        }
    };

export default delayMiddleware;
