import "./ModalContainer.css";
import * as React from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";

const ModalContainer = ({ openModal, setOpenModal, children, closeOnClickAnywhere }) => {
    return (
        <div>
            <Modal
                onClick={() => {
                    if (closeOnClickAnywhere) {
                        setOpenModal(false);
                    }
                }}
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: "auto",
                }}
            >
                <Fade in={openModal} timeout={300}>
                    <div>
                        <IconButton
                            onClick={() => setOpenModal(false)}
                            sx={{ ml: "auto", float: "right", zIndex: 10 }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                        <div className="modal-wrapper" style={{ height: "100vh" }}>
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalContainer;
