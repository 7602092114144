import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TypeFilter({
    questionTypes,
    choiceType,
    handleQuestionTypeChange
}) {
    
    return (
        <div id="type-filter_container">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">Question type</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={choiceType}
                    onChange={handleQuestionTypeChange}
                    autoWidth
                    label="Question type"
                >
                    {Object.keys(questionTypes).map((questionType, index) => {
                        return (
                            <MenuItem key={index} value={questionType}>
                                {questionTypes[questionType].label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export default TypeFilter;
