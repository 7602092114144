import { createSlice, current } from "@reduxjs/toolkit";
import { getStorage, setStorage, removeStorage } from "common/utils/localStorage";
import { onChoiceSelection, onReset, onSettingsSave, onSettingsReset } from "features/modules/quiz/reducers/quizSlice";

import { onMountMultiplayer, onGameSync, onResetMultiplayer } from "features/modules/multiplayer/reducers/multiplayerSlice";

const storage = getStorage("uiSettings");
const { activeModules, invertImage, convertToBW } = storage || {};

const defaultModules = ["home", "browse", "quiz" /*, "multiplayer" */];

const initialState = {
    // App Specific
    isAppLoading: false,
    activeModules: activeModules || defaultModules,

    // Instance(Quiz) Specific
    showGameOverReport: false,
    outcomeResponse: "unAnswered",

    // Domain(Magic) Specific
    invertImage: invertImage || false,
    convertToBW: convertToBW || false,

    // Module Specific
    showMask: false, // Browse
};

const resetUi = (state) => {
    state.showMask = false;
    state.activeModules = defaultModules;
    state.invertImage = false;
    state.convertToBW = false;
    state.showGameOverReport = false;
    state.outcomeResponse = "unAnswered";
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        onImageHasLoadedAfterSelection: (state, action) => {
            const { questionsLeft } = action.payload;
            if (questionsLeft < 1) {
                state.showGameOverReport = true;
            }
            state.outcomeResponse = "unAnswered";
        },
        updateConvertToBW: (state, action) => {
            state.convertToBW = action.payload;
        },
        updateInvertImage: (state, action) => {
            state.invertImage = action.payload;
        },
        updateOutcomeResponse: (state, action) => {
            state.outcomeResponse = action.payload;
        },
        updateActiveModules: (state, action) => {
            state.activeModules = action.payload;
        },
    },
    extraReducers: (builder) => {
        const syncClientUIState = (state, action) => {
            const { ui } = action.payload.currentGameState;
            
            Object.keys(state).forEach((key) => {
                if (ui[key] !== undefined) {
                    state[key] = ui[key];
                }
            });
        };
        builder
            .addCase(onChoiceSelection, (state, action) => {
                const { chosenCard } = action.payload;
                state.outcomeResponse = chosenCard.isCorrectChoice ? "correct" : "incorrect";
            })
            .addCase(onReset, (state, action) => {
                state.outcomeResponse = "unAnswered";
                state.showGameOverReport = false;
            })
            .addCase(onGameSync, (state, action) => {
                syncClientUIState(state, action);
            })
            .addCase(onMountMultiplayer, (state, action) => {})
            .addCase(onSettingsSave, (state, action) => {
                const { activeModules, invertImage, convertToBW } = action.payload;
                state.activeModules = activeModules;
                state.invertImage = invertImage;
                state.convertToBW = convertToBW;

                setStorage("uiSettings", {
                    activeModules,
                    invertImage,
                    convertToBW,
                });
            })
            .addCase(onSettingsReset, (state) => {
                resetUi(state);
                removeStorage("uiSettings");
            })
            .addCase(onResetMultiplayer, (state) => {
                resetUi(state);
            })
            .addDefaultCase((state, action) => {});
    },
});

export const {
    onImageHasLoadedAfterSelection,
    updateIsLoading,
    updateConvertToBW,
    updateInvertImage,
    updateOutcomeResponse,
    updateActiveModules,
} = uiSlice.actions;

export default uiSlice.reducer;
