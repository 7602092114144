import { createSlice, current } from "@reduxjs/toolkit";
import { getStorage, setStorage, removeStorage } from "common/utils/localStorage";

import source from "./../assets/sourcesConfig";

import { onSettingsSave, onSettingsReset } from "features/modules/quiz/reducers/quizSlice";

const storage = getStorage("appSettings");

const { activeSubCategories } = storage || {};

const defaultSourceType = "quiz";
const defaultCategory = "magic";
const defaultSubCategory = "phyrexia";

const initialState = {
    sourceType: defaultSourceType,
    sourceCategory: defaultCategory,
    activeSubCategories: activeSubCategories || [defaultSubCategory],
    gameAssets: source.type[defaultSourceType].category[defaultCategory].subCategory[defaultSubCategory].assets || [],
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        updateSourceType: (state, action) => {
            state.sourceType = action.payload;
        },
        updateSourceCategory: (state, action) => {
            state.sourceCategory = action.payload;
        },
        updateActiveSubCategories: (state, action) => {
            state.activeSubCategories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(onSettingsSave, (state, action) => {
                const { activeSubCategories } = action.payload;
                state.activeSubCategories = activeSubCategories;
                setStorage("appSettings", {
                    activeSubCategories,
                });
            })
            .addCase(onSettingsReset, (state, action) => {
                state.activeSubCategories = [ defaultSubCategory ];
                removeStorage("appSettings");
            })
            .addDefaultCase((state, action) => {});
    },
});

export const { updateSourceType, updateActiveSubCategories, updateActiveSubCategory } = appSlice.actions;

export default appSlice.reducer;
