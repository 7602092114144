module.exports = {
    type: {
        quiz: {
            category: {
                magic: {
                    name: "Magic",
                    subCategory: {
                        phyrexia: {
                            name: "Phyrexia",
                            assets: require(`./../assets/quizzes/magic/phyrexia.json`),
                        },
                        brothersWar: {
                            name: "Brothers' War",
                            assets: require(`./../assets/quizzes/magic/brothersWar.json`),
                        },
                    },
                },
                // star_wars: {
                //     name: "Star Wars",
                //     theForceAwakens: {
                //         name: "The Force Awakens",
                //         assets: require(`./../assets/quizzes/star_wars/theForceAwakens.json`),
                //     },
                // },
                // doctor_who: {
                //     name: "Doctor Who",
                //     season1: {
                //         name: "Season 1",
                //         assets: require(`./../assets/quizzes/doctor_who/doctorWhoSeason1.json`),
                //     },
                // },
            },
        },
        maze: {},
        jigsaw: {},
    },
};
