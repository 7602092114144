import { useSelector } from "react-redux";

import "./App.css";

import Settings from "./features/app/settings/views/Settings";
import HelpModal from "./features/app/components/HelpModal";
import Routes from "./routes";

function App() {
    const { outcomeResponse } = useSelector((state) => state.ui);
    const { activeGameId } = useSelector((state) => state.multiplayer);

    let backgroundColorTable = {
        unAnswered: "white",
        correct: "lightGreen",
        incorrect: "#FFCCCB",
    };

    const isMultiplayerGameInProgress = window.location.pathname.includes("multiplayer") && activeGameId;
    return (
        <div className="app" style={{ backgroundColor: backgroundColorTable[outcomeResponse] }}>
            
            <div className="app-container">

                { !isMultiplayerGameInProgress && <Settings /> }
                <HelpModal />
                <Routes />
            </div>

        </div>
    );
}

export default App;
