import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import "./Artwork.css";

import { questionTypes } from "./../assets/quizConfig";

import { onMountArtwork, onUnmountArtwork } from "./../reducers/quizSlice";

import Choices from "./../components/Choices";
import GameDetails from "./../components/GameDetails";
import DisplayArtwork from "./../components/DisplayArtwork";
import GameOverReport from "../components/GameOverReport";

function ArtworkQuiz({ isMultiplayerGame }) {
    const dispatch = useDispatch();
    const { showGameOverReport } = useSelector((state) => state.ui);
    const { choiceType } = useSelector((state) => state.quiz);
    const [isArtworkQuizLoading, setIsArtworkQuizLoading] = useState(true);

    useEffect(() => {
        dispatch(onMountArtwork({ isMultiplayerGame }));
        return () => {
            dispatch(onUnmountArtwork());
        };
    }, []);

    if (showGameOverReport) {
        return <GameOverReport />;
    }

    return (
        <div className="artwork-quiz">
            <div className="artwork_container">
                <div className="title">
                    <h2>{questionTypes[choiceType].prompt}</h2>
                </div>

                {!isMultiplayerGame && <GameDetails />}
                <DisplayArtwork setIsArtworkQuizLoading={setIsArtworkQuizLoading} />
                <Choices
                    isMultiplayerGame={isMultiplayerGame}
                    isArtworkQuizLoading={isArtworkQuizLoading}
                    setIsArtworkQuizLoading={setIsArtworkQuizLoading}
                />
            </div>
        </div>
    );
}

export default ArtworkQuiz;
