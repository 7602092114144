import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import CreateIcon from "@mui/icons-material/Create";
import PeopleIcon from "@mui/icons-material/People";

import Home from "features/modules/home/views/Home";
import Browse from "features/modules/browse/views/Browse";
import ArtworkQuiz from "features/modules/quiz/views/Artwork";
import Multiplayer from "features/modules/multiplayer/views/Multiplayer";

const modules = {
    home: {
        order: 1,
        defaultRoute: true,
        name: "Home",
        path: "/",
        icon: <HomeIcon />,
        component: <Home />,
    },
    browse: {
        order: 2,
        name: "Browse",
        path: "/browse",
        icon: <SchoolIcon />,
        component: <Browse />,
    },
    quiz: {
        order: 3,
        name: "Quiz",
        path: "/quiz",
        icon: <CreateIcon />,
        component: <ArtworkQuiz />,
    },
    // multiplayer: {
    //     order: 4,
    //     name: "Multiplayer(Beta)",
    //     path: `/multiplayer`,
    //     icon: <PeopleIcon />,
    //     component: <Multiplayer />,
    // },
    // multiplayerById: {
    //     routesOnly: true,
    //     name: "Multiplayer",
    //     path: `/multiplayer`,
    //     parameters: "/:gameId",
    //     icon: <PeopleIcon />,
    //     component: <Multiplayer />,
    // },
};

export { modules };
