import React, { useEffect } from "react";
import Button from "@mui/material/Button";

import { questionTypes } from "./../assets/quizConfig";

import { useSelector, useDispatch } from "react-redux";
import { updateOutcomeResponse } from "features/app/reducers/uiSlice";
import { onChoiceSelection, getNextQuestion } from "./../reducers/quizSlice";
import { onChoiceSelectionMultiplayer, getNextQuestionMultiplayer } from "features/modules/multiplayer/reducers/multiplayerSlice";

import { getStorage } from "common/utils/localStorage";

import Loader from "common/components/Loader";

const Choices = ({ isMultiplayerGame, isArtworkQuizLoading, setIsArtworkQuizLoading }) => {
    const dispatch = useDispatch();
    const { choices, choiceType } = useSelector((state) => state.quiz);

    const handleSubmitChoice = (chosenCard) => {
        if (isMultiplayerGame) {
            const userHashStorage = getStorage("userHash");
            dispatch(onChoiceSelectionMultiplayer({ chosenCard, userHashStorage }));
            dispatch(getNextQuestionMultiplayer({ meta: { delay: 1000 }}));
        } else {
            dispatch(onChoiceSelection({ chosenCard }));
            dispatch(getNextQuestion({ meta: { delay: 333 } }));
        }
        setIsArtworkQuizLoading(true);
    };

    useEffect(() => {
        if (!isArtworkQuizLoading) {
            dispatch(updateOutcomeResponse("unAnswered"));
        }
    }, [isArtworkQuizLoading]);

    if (isArtworkQuizLoading) {
        return <Loader />;
    }

    return (
        <div className="card_choices">
            {choices.map((card, index) => {
                const { handleDisplayValue, choicesFullWidth, denseText } = questionTypes[choiceType];
                return (
                    <Button
                        fullWidth={choicesFullWidth}
                        disabled={isArtworkQuizLoading}
                        key={index}
                        variant="contained"
                        onClick={() => handleSubmitChoice(card)}
                        value={card.urlFriendlyName}
                        style={{
                            fontSize: denseText ? "0.8rem" : "1rem",
                            lineHeight: denseText ? "1.2em" : "unset",
                        }}
                    >
                        {handleDisplayValue ? handleDisplayValue(card) : card[choiceType]}
                    </Button>
                );
            })}
        </div>
    );
};

export default Choices;
