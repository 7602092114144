
import './Home.css';

const Home = () => {
    return (
        <div className="home">
            <div className="home__container">
                <div className="home__header"></div>
            </div>
        </div>
    );
};

export default Home;
