import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function BasicSelect({ lableText, currentValue, handleModuleChange, options }) {
    return (
        <Box>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-simple-select-label">{lableText}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentValue}
                    label={lableText}
                    onChange={handleModuleChange}
                    input={<OutlinedInput label={lableText} />}
                    MenuProps={MenuProps}

                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.displayValue}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
