import { configureStore } from "@reduxjs/toolkit";

import appReducer from "features/app/reducers/appSlice";
import uiReducer from "features/app/reducers/uiSlice";

import quizReducer from "features/modules/quiz/reducers/quizSlice";
import multiplayerReducer from "features/modules/multiplayer/reducers/multiplayerSlice";
import browseReducer from "features/modules/browse/reducers/browseSlice";

import sourceMiddleware from "features/app/middleware/source";
import delayMiddleware from "common/middleware/delay";
import gunMiddleware from "features/modules/multiplayer/middleware/gun";

export const store = configureStore({
    middleware: [sourceMiddleware, delayMiddleware, gunMiddleware],
    reducer: {
        app: appReducer,
        browse: browseReducer,
        quiz: quizReducer,
        ui: uiReducer,
        multiplayer: multiplayerReducer,
    },
});
