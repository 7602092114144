
import { useState } from 'react';

export default function useTimer() {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [timerId, setTimerId] = useState(null);

    const getFormattedTime = () => {
        let formattedTime = "";
        if (minutes < 10) {
            formattedTime += "0";
        }
        formattedTime += minutes + ":";
        if (seconds < 10) {
            formattedTime += "0";
        }
        formattedTime += seconds;
        return formattedTime;
    };

    const startTimer = () => {
        if (timerId === null) {
            const timerId = setInterval(() => {
                if (seconds < 59) {
                    setSeconds((secs)=>secs + 1);
                } else {
                    setSeconds(0);
                    setMinutes((mins)=>mins + 1);
                }
            }, 1000);
            setTimerId(timerId);
        }
    };

    const stopTimer = () => {
        clearInterval(timerId);
        setTimerId(null);
    };

    const resetTimer = () => {
        setSeconds(0);
        setMinutes(0);
    };

    return {
        seconds,
        minutes,
        getFormattedTime,
        startTimer,
        stopTimer,
        resetTimer,
    };
}