import { Routes as ReactRouterRoutes, Route, useLocation } from "react-router-dom";

import Layout from "common/components/Layout";
import NoMatch from "common/views/NoMatch";

import { modules } from "features/app/assets/modulesConfig";

import { useSelector } from "react-redux";
import { useEffect } from "react";

import ReactGA from "react-ga4";
ReactGA.initialize("G-0W384ZV9B7");

const Routes = () => {
    const location = useLocation();
    const { ui } = useSelector((state) => state);
    let routesToShow = Object.keys(modules).filter(
        (name) => ui.activeModules.includes(name) || modules[name].routesOnly
    );

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page_path: location.pathname + location.search });
    }, [location]);

    return (
        <ReactRouterRoutes>
            <Route path="/" element={<Layout />}>
                {routesToShow.map((moduleName, index) => {
                    return (
                        <Route
                            key={moduleName + index}
                            index={modules[moduleName]?.defaultRoute}
                            path={modules[moduleName].path + (modules[moduleName]?.parameters || "")}
                            element={modules[moduleName].component}
                        />
                    );
                })}

                <Route path="*" element={<NoMatch />} />
            </Route>
        </ReactRouterRoutes>
    );
};

export default Routes;
