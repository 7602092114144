import { CircularProgress } from "@mui/material";

const Loader = ({ hidden }) => {
    return (
        <div style={{ textAlign: "center", padding: "30px 0", display: hidden ? "none" : "block" }}>
            <CircularProgress size={60} disableShrink />
        </div>
    );
};

export default Loader;
