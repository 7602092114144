import { createSlice, current } from "@reduxjs/toolkit";
import { getStorage, setStorage, removeStorage } from "common/utils/localStorage";
import {
    onGameSync,
    onUnmountMultiplayer,
    onGameCreationMultiplayer,
    onLobbyArrivalMultiplayer,
    onChoiceSelectionMultiplayer,
    onResetMultiplayer,
} from "features/modules/multiplayer/reducers/multiplayerSlice";

import { onMountBrowser } from "features/modules/browse/reducers/browseSlice";

const storage = getStorage("quizSettings");

const { choiceType, manaFilters, includeLands, numberOfQuestions } = storage || {};

const initialState = {
    // Instance(Quiz) Specific
    choicePool: [],
    choices: [],
    choiceType: choiceType || "name", // Create an interface for this for each Domain
    askedChoices: [],
    numberOfQuestions: numberOfQuestions || 10,
    questionsLeft: numberOfQuestions || 10,

    // Domain(Magic) Specific
    manaFilters: manaFilters || [],
    includeLands: includeLands || false,
};

const handleReset = (state) => {
    const { askedChoices, manaFilters, includeLands, numberOfQuestions, questionsLeft } =
        initialState;

    state.numberOfQuestions = numberOfQuestions;
    state.questionsLeft = questionsLeft;
    state.askedChoices = askedChoices;
    state.manaFilters = manaFilters;
    state.includeLands = includeLands;
};

const filterByIncludeLands = (choice) => !choice.type.toLowerCase().includes("land");

const filterByManaCost = (choice, manaFilters) => {
    let type = "colorIdentity";
    for (let i = 0; i < choice[type].length; i++) {
        let manaVal = choice[type][i].toLowerCase();
        if (manaFilters.includes(manaVal)) {
            return true;
        }
    }
    return false;
};

const filterByIsValid = (choice, type) => {
    let value = choice[type];
    return Array.isArray(value) ? value.length > 0 : value;
};

const filterData = (state, action) => {
    let { gameAssets } = action.payload;

    if (state.choiceType) {
        gameAssets = gameAssets.filter((choice) => {
            return filterByIsValid(choice, state.choiceType);
        });
    }

    if (!state.includeLands) {
        gameAssets = gameAssets.filter(filterByIncludeLands);
    }

    if (state.manaFilters.length > 0) {
        gameAssets = gameAssets.filter((choice) => filterByManaCost(choice, state.manaFilters));
    }
    return gameAssets;
};

const filterChoices_nonSelectedType = (_choices, _selectedChoices, _type) => {
    if (_choices.length < 1) throw new Error("Choices are required");
    if (!_type) throw new Error("Type is required");

    return _choices.filter((_choice) => {
        return !_selectedChoices.some((selection) => {
            if (Array.isArray(selection[_type])) {
                return selection[_type].every((value) => _choice[_type].includes(value));
            }
            return selection[_type] === _choice[_type];
        });
    });
};

const getRandomIndex = (_array) => {
    return Math.floor(Math.random() * _array.length);
};

const getCorrectChoice = (_choicePool, _choiceType) => {
    const correctChoiceIndex = getRandomIndex(_choicePool);
    let correctChoiceObj = _choicePool.splice(correctChoiceIndex, 1)[0];
    return { ...correctChoiceObj, isCorrectChoice: true };
};

const getQuestion = (_choicePool, _choiceType) => {
    const choicesForQuestion = [getCorrectChoice(_choicePool, _choiceType)];

    let choicesLeft = [..._choicePool];

    if (choicesLeft.length < 2) {
        console.error("Not enough choices to generate question");
        return [];
    }

    for (let i = 0; i < 2; i++) {
        choicesLeft = filterChoices_nonSelectedType(choicesLeft, choicesForQuestion, _choiceType);
        let index = getRandomIndex(choicesLeft);
        choicesForQuestion.push(choicesLeft[index]);
    }

    choicesForQuestion.sort(() => (Math.random() > 0.5 ? 1 : -1));

    return choicesForQuestion;
};

export const initializeGame = (state, action) => {
    const newChoicePool = filterData(action.payload.quiz || state, action);
    return {
        choicePool: newChoicePool,
        choices: getQuestion(newChoicePool, state.choiceType),
    };
};

export const quizSlice = createSlice({
    name: "quiz",
    initialState,
    reducers: {
        onMountArtwork: (state, action) => {
            const { choicePool, choices } = initializeGame(state, action);
            const { isMultiplayerGame } = action.payload;
            state.choicePool = choicePool;
            if (!isMultiplayerGame) {
                state.choices = choices;
            }
        },
        onUnmountArtwork: (state) => {
            // handleReset(state);
        },

        onChoiceSelection: (state, action) => {
            const { chosenCard } = action.payload;

            state.askedChoices.push({
                choices: state.choices,
                selectionIndex: state.choices.findIndex((choice) => choice.id === chosenCard.id),
            });
        },
        getNextQuestion: (state) => {
            state.questionsLeft--;
            state.choices = getQuestion(state.choicePool, state.choiceType);
        },

        onReset: (state, action) => {
            handleReset(state);
            state.choicePool = filterData(state, action);
        },

        onSettingsReset: (state, action) => {
            handleReset(state);
            removeStorage("quizSettings");
            state.choicePool = filterData(state, action);
        },

        onSettingsSave: (state, action) => {
            handleReset(state);
            const { choiceType, manaFilters, includeLands, freeScroll, hideCardToggle, numberOfQuestions } =
                action.payload;

            state.choiceType = choiceType;
            state.manaFilters = manaFilters;
            state.includeLands = includeLands;
            state.freeScroll = freeScroll;
            state.hideCardToggle = hideCardToggle;

            state.choicePool = filterData(state, action);

            state.numberOfQuestions = numberOfQuestions;
            state.questionsLeft = numberOfQuestions;
            state.choices = getQuestion(state.choicePool, choiceType);

            setStorage("quizSettings", {
                choiceType,
                manaFilters,
                includeLands,
                freeScroll,
                hideCardToggle,
                numberOfQuestions,
            });
        },
    },
    extraReducers: (builder) => {
        const syncClientGameState = (state, action) => {
            const { quiz } = action.payload.currentGameState;

            Object.keys(state).forEach((key) => {
                if (quiz[key] !== undefined) {
                    state[key] = quiz[key];
                }
            });
        };
        builder
            .addCase(onGameSync, (state, action) => {
                syncClientGameState(state, action);
            })
            .addCase(onMountBrowser, (state, action) => {
                state.choicePool = filterData(state, action);
            })
            .addCase(onChoiceSelectionMultiplayer, (state, action) => {})
            .addCase(onGameCreationMultiplayer, (state, action) => {})
            .addCase(onUnmountMultiplayer, (state, action) => {
                handleReset(state);
            })
            .addCase(onLobbyArrivalMultiplayer, (state, action) => {})
            .addCase(onResetMultiplayer, (state, action) => {
                handleReset(state);
                const { choicePool } = initializeGame(state, action);
                state.choicePool = choicePool;
            })
            .addDefaultCase((state, action) => {});
    },
});

export const {
    onMountArtwork,
    onUnmountArtwork,

    onSettingsSave,
    onSettingsReset,
    onFilterSelection,
    onChoiceSelection,
    getNextQuestion,
    onReset,
} = quizSlice.actions;

export default quizSlice.reducer;
