import { useState } from "react";
import { useLocation } from "react-router-dom";

import ModalContainer from "common/components/ModalContainer"

import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import "./HelpModal.css";

const helpMessages = require("./../assets/helpMessages.json");

function HelpModal() {
    const location = useLocation();
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    };

    if (!helpMessages[location.pathname]) return <></>;

    return (
        <div className="help-modal">
            <IconButton sx={{ position: "fixed", left: 5, top: 5, zIndex: 1 }} onClick={() => setOpenModal(true)}>
                <InfoIcon
                    sx={{
                        fill: "black",
                        fontSize: "40px",
                        padding: "10px",
                        boxShadow: "3px 3px 10px -2px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                    }}
                />
            </IconButton>
            <ModalContainer openModal={openModal} setOpenModal={setOpenModal}>
                <div className="help-modal-content">
                    <h1>{helpMessages[location.pathname].title}</h1>
                    <p>{helpMessages[location.pathname].message}</p>
                    <button onClick={closeModal}>Close</button>
                </div>
            </ModalContainer>
        </div>
    );
}

export default HelpModal;
