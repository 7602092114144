import { useDispatch, useSelector } from "react-redux";
import { onImageHasLoadedAfterSelection } from "features/app/reducers/uiSlice";

function DisplayArtwork({ setIsArtworkQuizLoading }) {
    const dispatch = useDispatch();
    const { convertToBW, invertImage } = useSelector((state) => state.ui);
    const { choices, questionsLeft } = useSelector((state) => state.quiz);

    if (choices.length < 1)  {
        return <div>No questions available</div>
    };

    const correctCard = choices.find((card) => card.isCorrectChoice);

    const handleImageLoad = () => {
        setIsArtworkQuizLoading(false);
        dispatch(onImageHasLoadedAfterSelection({ questionsLeft }));
    };
    
    return (
        <div id="selected_card_image">
            <img
                rel="preload"
                src={`/images/${correctCard?.source}/artwork/${correctCard?.urlFriendlyName}.png`}
                alt={choices[0]?.image}
                style={{
                    filter: convertToBW ? "grayscale(100%)" : "grayscale(0%)",
                    transform: invertImage ? "scale(-1)" : "scale(1)",
                    transition: "0.333s",
                }}
                onLoad={handleImageLoad}
                draggable="false"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `https://via.placeholder.com/310x223?text=Image+Not+Found: ${choices[0]?.urlFriendlyName}`;
                }}
            />
        </div>
    );
}

export default DisplayArtwork;
