import { createSlice, current } from "@reduxjs/toolkit";
import { getStorage, setStorage, removeStorage } from "common/utils/localStorage";
import {
    onGameSync,
    onUnmountMultiplayer,
    onResetMultiplayer,
} from "features/modules/multiplayer/reducers/multiplayerSlice";

import { onSettingsSave } from "features/modules/quiz/reducers/quizSlice";

const storage = getStorage("browseSettings");

const { freeScroll, hideCardToggle } = storage || {};

const initialState = {
    freeScroll: freeScroll || false,
    hideCardToggle: hideCardToggle || false,
};

const handleReset = (state) => {
    const { freeScroll, hideCardToggle } = initialState;
    state.freeScroll = freeScroll;
    state.hideCardToggle = hideCardToggle;
};

export const browseSlice = createSlice({
    name: "browse",
    initialState,
    reducers: {
        onMountBrowser: (state, action) => {},
    },
    extraReducers: (builder) => {
        const syncClientGameState = (state, action) => {
            const { browse } = action.payload.currentGameState;

            Object.keys(state).forEach((key) => {
                if (browse[key] !== undefined) {
                    state[key] = browse[key];
                }
            });
        };
        builder
            .addCase(onGameSync, (state, action) => {
                syncClientGameState(state, action);
            })
            .addCase(onUnmountMultiplayer, (state, action) => {
                handleReset(state);
            })
            .addCase(onResetMultiplayer, (state, action) => {
                handleReset(state);
            })
            .addCase(onSettingsSave, (state, action) => {
                handleReset(state);
                const { freeScroll, hideCardToggle } = action.payload;

                state.freeScroll = freeScroll;
                state.hideCardToggle = hideCardToggle;

                setStorage("browseSettings", {
                    freeScroll,
                    hideCardToggle,
                });
            })
            .addDefaultCase((state, action) => {});
    },
});

export const { onMountBrowser } = browseSlice.actions;

export default browseSlice.reducer;
