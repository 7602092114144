import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "@mui/material";

import { questionTypes } from "./../../modules/quiz/assets/quizConfig"

import BasicSelect from "./../../../common/components/BasicSelect";
import MultiSelection from "./../../../common/components/MultiSelection";

import ManaFilter from "./ManaFilter";
import TypeFilter from "./TypeFilter";
import AdditionalFilters from "./AdditionalFilters";

import { modules } from "./../../app/assets/modulesConfig"
import source from "./../../app/assets/sourcesConfig";

import { onSettingsSave, onSettingsReset } from "./../../modules/quiz/reducers/quizSlice";
import { updateActiveSubCategories } from "./../../app/reducers/appSlice";

import "./Filters.css";

import { useSelector, useDispatch } from "react-redux";

const { subCategory: allCategories } = source.type["quiz"].category["magic"];

function Filters({ closeModal }) {
    let location = useLocation();
    const dispatch = useDispatch();
    const { quiz, ui, app, browse } = useSelector((state) => state);
    const [settings, setSettings] = useState({ ...quiz, ...ui, ...app, ...browse });
    const {
        convertToBW,
        invertImage,
        activeModules,
        choiceType,
        manaFilters,
        includeLands,
        freeScroll,
        hideCardToggle,
        numberOfQuestions,
        activeSubCategories,
    } = settings;
    const handleSave = () => {
        dispatch(onSettingsSave(settings));
        dispatch(updateActiveSubCategories(activeSubCategories));
        closeModal();
    };
    const handleReset = () => {
        dispatch(onSettingsReset(settings));
        closeModal();
    };

    const handleSettingChange = (settingName, value) => {
        setSettings({ ...settings, [settingName]: value });
    };

    const handleModuleChange = (event) => {
        const { value } = event.target;
        handleSettingChange("activeModules", typeof value === "string" ? value.split(",") : value);
    };

    const handleSourceChange = (e) => {
        const { value } = e.target;
        handleSettingChange("activeSubCategories", typeof value === "string" ? value.split(",") : value);
    };

    const handleNumberOfQuestionsChange = (e) => {
        const { value } = e.target;
        handleSettingChange("numberOfQuestions", value);
    };

    const handleManaFilterChange = (e) => {
        const { value: manaValue, checked } = e.target;
        let currentManaFilters = [...manaFilters, manaValue];
        let newManaFilters = manaFilters.filter((mana) => mana !== manaValue);
        if (checked) {
            newManaFilters = currentManaFilters;
        }
        handleSettingChange("manaFilters", newManaFilters);
    };

    const handleQuestionTypeChange = (e) => {
        handleSettingChange("choiceType", e.target.value);
    };

    let additionalFilters = [
        {
            label: "Black/White",
            value: convertToBW,
            moduleCompatibilities: ["/quiz", "/browse", "/multiplayer"],
            onChange: (e) => {
                handleSettingChange("convertToBW", e.target.checked);
            },
        },
        {
            label: "Invert",
            value: invertImage,
            moduleCompatibilities: ["/quiz", "/browse", "/multiplayer"],
            onChange: (e) => {
                handleSettingChange("invertImage", e.target.checked);
            },
        },
        {
            label: "Include Lands",
            value: includeLands,
            moduleCompatibilities: ["/quiz", "/browse", "/multiplayer"],
            onChange: (e) => {
                handleSettingChange("includeLands", e.target.checked);
            },
        },
        {
            label: "Free Scroll",
            value: freeScroll,
            moduleCompatibilities: ["/browse"],
            onChange: (e) => {
                handleSettingChange("freeScroll", e.target.checked);
            },
        },
        {
            label: "Flashcard Mode",
            value: hideCardToggle,
            moduleCompatibilities: ["/browse"],
            onChange: (e) => {
                handleSettingChange("hideCardToggle", e.target.checked);
            },
        },
    ];
    let totalChoices = activeSubCategories.reduce((acc, subCategory) => {
        return acc + allCategories[subCategory].assets.length;
    }, 0);

    const questionSelectionOptions = [
        { value: 10, displayValue: 10 },
        { value: 25, displayValue: 25 },
        { value: 50, displayValue: 50 },
        { value: 100, displayValue: 100 },
        { value: 150, displayValue: 150 },
        { value: 250, displayValue: 250 },
        { value: totalChoices, displayValue: `All(${totalChoices})` },
    ];
    console.log(Object.keys(modules)?.filter((key) => modules[key]?.order))

    return (
        <div className="filters">
            <div className="filters__primaries">
                <MultiSelection
                    lableText="Choose your set(s)"
                    selections={Object.keys(allCategories)}
                    handleModuleChange={handleSourceChange}
                    options={activeSubCategories}
                />

                <MultiSelection
                    lableText="Choose your module(s)"
                    selections={Object.keys(modules).filter((key) => modules[key].order)}
                    handleModuleChange={handleModuleChange}
                    options={activeModules}
                />

                {location.pathname.toLowerCase().includes("/quiz") && (
                    <>
                        <BasicSelect
                            lableText="Choose how many questions"
                            options={questionSelectionOptions.filter((option) => {
                                return Number(option.value) <= totalChoices || option.value === "all";
                            })}
                            handleModuleChange={handleNumberOfQuestionsChange}
                            currentValue={numberOfQuestions}
                        />
                        <TypeFilter
                            questionTypes={questionTypes}
                            choiceType={choiceType}
                            handleQuestionTypeChange={handleQuestionTypeChange}
                        />
                    </>
                )}
            </div>

            <ManaFilter manaFilters={manaFilters} handleManaFilterChange={handleManaFilterChange} />

            <AdditionalFilters additionalFilters={additionalFilters} location={location} />

            <div className="settings__save-reset">
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleReset}>Reset</Button>
                <Button onClick={closeModal}>Cancel</Button>
            </div>
        </div>
    );
}

export default Filters;
