import source from "./../assets/sourcesConfig.js";

import {
    onMountArtwork,
    onUnmountArtwork,
    onSettingsSave,
    onSettingsReset,
    onReset,
} from "features/modules/quiz/reducers/quizSlice";

import {
    onMountMultiplayer,
    onGameCreationMultiplayer,
    onLobbyArrivalMultiplayer,
    onResetMultiplayer
} from "features/modules/multiplayer/reducers/multiplayerSlice";

import { onMountBrowser } from "features/modules/browse/reducers/browseSlice";

const sourceMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const { app } = getState();

        const actionsNeedingAssetsGeneration = [
            onMountMultiplayer.type,
            onMountArtwork.type,
            onUnmountArtwork.type,
            onMountBrowser.type,
            onGameCreationMultiplayer.type,
            onSettingsSave.type,
            onSettingsReset.type,
            onLobbyArrivalMultiplayer.type,
            onReset.type,
            onResetMultiplayer.type,
        ];

        if (actionsNeedingAssetsGeneration.includes(action.type)) {
            let { sourceType, sourceCategory, activeSubCategories } = app;
            if (action.type === onSettingsSave.type || action.type === onSettingsReset.type) {
                activeSubCategories = action.payload.activeSubCategories;
            }
            const gameAssets = activeSubCategories.reduce((acc, subCategoryName) => {
                return [
                    ...acc,
                    ...source.type[sourceType].category[sourceCategory].subCategory[subCategoryName].assets,
                ];
            }, []);
            action.payload = {
                ...action.payload,
                gameAssets,
            };
        }

        next(action);
    };

export default sourceMiddleware;
