import "./ManaFilter.css";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { blue, red, green, grey } from "@mui/material/colors";

function ManaFilter({ manaFilters, handleManaFilterChange }) {
    
    const manaObject = [
        { name: "Colorless", value: "c", color: grey[600] },
        { name: "Island", value: "u", color: blue[500] },
        { name: "Forest", value: "g", color: green[500] },
        { name: "Mountain", value: "r", color: red[500] },
        { name: "Swamp", value: "b", color: "black" },
        { name: "Plains", value: "w", color: grey[400] },
    ];
    
    return (
        <div id="mana-filter_container">
            {manaObject.map((mana, index) => {
                return (
                    <FormControlLabel
                        key={index}
                        value={mana.value}
                        control={
                            <Checkbox
                                className="mana-filter_checkbox"
                                onChange={handleManaFilterChange}
                                value={mana.value}
                                checked={manaFilters.includes(mana.value)}
                                sx={{
                                    color: mana.color,
                                    "&.Mui-checked": {
                                        color: mana.color,
                                    },
                                }}
                            />
                        }
                        label={mana.name}
                        labelPlacement="top"
                    />
                );
            })}
        </div>
    );
}

export default ManaFilter;
